import React from 'react';
import Card from '../../components/Card';
import image from '../../assets/speed.webp';
import {
  SpeedWrapper
} from './styles';

const Speed = () => {

  const speed = [{superreflex: 'Super Reflex'}];
  return (
    <SpeedWrapper>
      {speed.map((game) =>
        <Card
          key={Object.values(game)[0]}
          title={Object.values(game)[0]}
          category="Speed"
          image={image}
          path={`speed/${Object.keys(game)[0]}`}
        />
      )}
    </SpeedWrapper>
  );
}

export default Speed;