import React, { useState, useEffect } from "react";
import { StopWatch }  from "./styles.js";
import Timer from "./Timer";
import ControlButtons from "./ControlButtons";

function Cronometer ({ stateOfRound, maxTime, startGame, stopGame, pauseGame = () => {} }) {
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(true);
  const [time, setTime] = useState(0);

  useEffect(() => {
    let interval = null;

    if (isActive && isPaused === false) {
      interval = setInterval(() => {
        setTime((time) => time + 10);
      }, 10);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isActive, isPaused]);

  useEffect(() => {
    if (time === maxTime) setIsPaused(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  useEffect(() => {
    switch (stateOfRound) {
      case "PRES":
        setIsActive(true);
        setIsPaused(false);
      break;
      case "STOP":
        setIsActive(false);
        setTime(0);
      break;
      default:
        setIsActive(false);
        setTime(0);
      break;
    }
  }, [stateOfRound]);

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(false);
    startGame();
  };

  const handlePauseResume = () => {
    setIsPaused(!isPaused);
    pauseGame();
  };

  const handleReset = () => {
    setIsActive(false);
    setTime(0);
    stopGame();
  };

  return (
    <StopWatch>
      <Timer time={time} />
      <ControlButtons
        active={isActive}
        isPaused={isPaused}
        handleStart={handleStart}
        handlePauseResume={handlePauseResume}
        handleReset={handleReset}
      />
    </StopWatch>
  );
}

export default Cronometer;
