import React from 'react';
import {
  AboutWrapper
} from './styles';

const About = () => {


  return (
    <AboutWrapper>
      <p>Web page created by <span>DiPipero</span>.</p>
      <p><span>Contact:</span> contact@ezgames.es</p>
      <p><span>Twitch:</span> <a href="https://www.twitch.tv/DiPipero" target="_blank" rel="noreferrer">twitch.tv/DiPipero</a></p>
    </AboutWrapper>
  );
}

export default About;