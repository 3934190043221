import styled from 'styled-components';

export const Timer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Digits = styled.span`
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 3rem;
`;

export const MiliSec = styled.span`
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 3rem;
  color: #e42a2a;
`;

export const StopWatch = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 0px 0px;
  max-height: 100px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const Button = styled.div`
  margin: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  width: 10vw;
  height: 5vh;
  border-radius: 14px;
  display: flex;
  border: 2px solid black;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ButtonOne = styled.div`
  margin: 10px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  width: 100px;
  height: 5vh;
  border-radius: 14px;
  display: flex;
  border: 2px solid black;
  justify-content: center;
  align-items: center;
  cursor: pointer;  
  background-color: #e42a2a;
`;



