import React, { useRef, useEffect, useState } from 'react';
import { setCookie, getCookie, checkCookie } from '../../../utils';
import { GameBar } from '../../../components';
import {
  SRelfexContainer,
  ReflexButton,
  PanelWrapper,
  TimeInput
} from './styles';

const SuperReflex = () => {

  const inputRef = useRef(null);
  let startPressed = false;
  let bgChangeStarted = false;
  let startTime = new Date();
  let endTime = new Date();
  let timerID, intervalID;
  const [, setRecord] = useState(0); //This is done to refresh the cookies

  const stopTest = () => {
	  if(bgChangeStarted) {

	  	endTime=new Date();
      clearInterval(intervalID);
      clearTimeout(timerID);
      startPressed=false;
	  	bgChangeStarted=false;

      document.getElementById('actionButton').style.background="red";
      document.getElementById('actionButton').innerHTML  = "Start";
      inputRef.current.value = `${endTime.getTime()-startTime.getTime()} miliseconds`;

      if(getCookie('recordSuperReflex') === '' || parseInt(getCookie('recordSuperReflex')) > endTime.getTime()-startTime.getTime()){
        setCookie('recordSuperReflex', endTime.getTime()-startTime.getTime());
        checkCookie('recordSuperReflex');
        setRecord(endTime.getTime()-startTime.getTime());
      }
        
	  }
	  else {
	  	clearInterval(intervalID);
      clearTimeout(timerID);
	  	startPressed=false;
      document.getElementById('actionButton').style.background="red";
      document.getElementById('actionButton').innerHTML  = "Start";
      inputRef.current.value = "You pressed too early!";
	  }
  }

  const updateDisplay = (currentTime) => {
    inputRef.current.value = `${currentTime} miliseconds`;
    if(currentTime > 5000)
      stopTest();
  };

  const startit = () => {
    document.getElementById('actionButton').style.background="green";
    document.getElementById('actionButton').innerHTML  = "Click!!";
  	bgChangeStarted=true;
    startTime=new Date();
  	timerID=setInterval(() => updateDisplay(Date.now() - startTime), 10);
  }

  const start = () => {
    if(startPressed) return;
    startPressed = true;
    inputRef.current.value = `${0} miliseconds`;
    document.getElementById('actionButton').style.background="orange";
    document.getElementById('actionButton').innerHTML  = "Ready...";
    timerID = setTimeout(() => startit(), (Math.random() * (5 - 1) + 1) * 1000);
  }

  const buttonClicked = () => {
    if(!startPressed && !bgChangeStarted) {
      start();
      return;
    }
    stopTest();
    return;
  }

  useEffect(() => {
    document.getElementById('actionButton').style.background="red";
    document.getElementById('actionButton').innerHTML  = "Start";
    inputRef.current.value = `${0} miliseconds`;
  },[]);

  return (
    <SRelfexContainer>
      <GameBar
        title="Super Reflex"
        description="Try to click as faster as you can when BUTTON turns GREEN."
        recordID="recordSuperReflex"
        recordUnity="miliseconds"
      />
      <PanelWrapper>
        <TimeInput id='timeInput' ref={inputRef} readOnly={true} />
        <ReflexButton id='actionButton' onClick={() => buttonClicked()}>Click</ReflexButton>
      </PanelWrapper> 
    </SRelfexContainer>
  );
}

export default SuperReflex;