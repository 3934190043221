import styled from 'styled-components';

export const NumberKeyboardWrapper = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: column; 
`;

export const Number = styled.div`
  margin: 5px;
  padding: 20px;
  cursor: pointer;
  border: 2px solid;
  border-radius: 10px;
  transition: 0.2s;
  :hover{
    background-color: white;
  }
`;

export const NumberRow = styled.div`
  display: flex;
`;