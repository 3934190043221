import styled from 'styled-components';

export const AboutWrapper = styled.div`
  display: block;
  margin: 20px;

  span {
    font-weight: bold;
  }

  a {
    text-decoration: none;
  }
`;
