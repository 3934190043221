import styled from 'styled-components';

export const SButtonWrapper = styled.div``;

export const SButton = styled.div`
  width: 100px;
  padding: 5px 0;
  border: 2px solid;
  border-radius: 10px;
  text-align: center;
  background-color: white;
`;