import React from "react";
import { Button, ButtonGroup, ButtonOne } from "./styles.js";

export default function ControlButtons(props) {
  const StartButton = (
    <ButtonOne onClick={props.handleStart}>
      Start
    </ButtonOne>
  );
  const ActiveButtons = (
    <ButtonGroup>
      <Button onClick={props.handleReset}>
        Reset
      </Button>
      {/* <Button onClick={props.handlePauseResume}>
        {props.isPaused ? "Resume" : "Pause"}
      </Button> */}
    </ButtonGroup>
  );

  return (
    <div className="Control-Buttons">
      <div>{props.active ? ActiveButtons : StartButton}</div>
    </div>
  );
}
