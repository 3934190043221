import React from 'react';
import { Link } from "react-router-dom";
import {
  CardWrapper,
  CardImageDiv,
  CardImage,
  CardTextDiv
} from './styles';
import { useMediaDevices } from '../../utils';

const Card = ({title, category, image, path}) => {

  const { mediaIsPhone } = useMediaDevices();

  return (
    <CardWrapper width={mediaIsPhone ? '100%' : '30%'}>
      <Link to={`/${path}`} style={{ textDecoration: 'none' }}>
        {image ? 
        <CardImageDiv>
          <CardImage src={image} />
        </CardImageDiv> : null}
        <CardTextDiv>{title}</CardTextDiv>
        <CardTextDiv>{category}</CardTextDiv>
      </Link>
    </CardWrapper>
  );
}

export default Card;