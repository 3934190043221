import { setCookie, getCookie, checkCookie } from "./cookie";
import { useCountdown, useCountdownSec, useMediaDevices } from "./hooks";

const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export {
  setCookie,
  getCookie,
  checkCookie,
  sleep,
  useCountdown,
  useCountdownSec,
  useMediaDevices
};