import React, { useEffect, useState } from 'react';
import { buttonsRow1, buttonsRow2 } from './ButtonsData';
import { StandardButton } from '../../../components';
import { setCookie, getCookie, sleep, useMediaDevices } from '../../../utils';
import {
  GameBar,
  RememberColoursContainer,
  PanelWrapper,
  ColorsRow,
  ColorContainer,
  ButtonText,
  InfoText
} from './styles';

const RememberColours = () => {

  const [round, setRound] = useState(0);
  const [secuenceToPress, setSecuenceToPress] = useState([]);
  const [stateOfRound, setStateOfRound] = useState("STOP");
  const [buttonToShow, setButtonToShow] = useState(-1);
  const [, setRecord] = useState(0); //This is done to refresh the cookies
  let secuenceindex = 0;

  const { mediaIsPhone } = useMediaDevices();
  
  async function showingSecuence() {
    await sleep(1000);
    for (let i = 0; i < round; i++) {
        setButtonToShow(secuenceToPress[i]);
        await sleep(1000);
        setButtonToShow(-1);
        await sleep(1000);
    }
    setButtonToShow(-1);
    setStateOfRound("PRES");
  }

  const startTheGame = () => {
    setRound(1);
    setStateOfRound("SHOW");
    setSecuenceToPress([Math.floor(Math.random() * 4)]);
  }

  const pressColorButton = (indexButton) => {
    if (stateOfRound !== "PRES") return;

    if (indexButton === secuenceToPress[secuenceindex]) {
      secuenceindex++;
      if (round === secuenceindex){
        secuenceindex = 0;
        setSecuenceToPress([...secuenceToPress, Math.floor(Math.random() * 4)]);
        setRound(round+1);
        setStateOfRound("SHOW");
      }else{
        return;
      }
      return;
    } else{
      if(getCookie('record') < round - 1) {
        setCookie('record', round - 1);
        setRecord(round - 1);
      }
        
      setRound(0);
      setButtonToShow(-1);
      setSecuenceToPress([]);
      setStateOfRound("OVER");
      alert('game over');
    };
  };

  useEffect(() => {
    if(stateOfRound === "SHOW")
      showingSecuence();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[stateOfRound])

  return (
    <RememberColoursContainer display={mediaIsPhone ? 'block' : 'flex'}>
      <GameBar width={mediaIsPhone ? '100%' : '30%'}>
        <div>
          <h2>Remember the colours</h2>
          <h4>Repeat the secuence of the colours.</h4>
        </div>
        <StandardButton title="Start" clickFunction={() => startTheGame()} />
        <InfoText>State of the round: {stateOfRound}</InfoText>
        <InfoText>Round: {round}</InfoText>
        <InfoText>Record: {getCookie('record') ? getCookie('record') : 0}</InfoText>
      </GameBar>
      <PanelWrapper width={mediaIsPhone ? '100%' : '70%'}>
        <ColorsRow>
          {buttonsRow1.map((b) =>
            <ColorContainer
              key={b.indexNumber}
              color={buttonToShow === b.indexNumber ? "white" : b.color}
              onClick={() => pressColorButton(b.indexNumber)}
              stateOfRound={stateOfRound}
            >
              <ButtonText>{b.color.toUpperCase() }</ButtonText>
            </ColorContainer>
          )}
        </ColorsRow>
        <ColorsRow>
          {buttonsRow2.map((b) =>
            <ColorContainer
              key={b.indexNumber}
              color={buttonToShow === b.indexNumber ? "white" : b.color}
              onClick={() => pressColorButton(b.indexNumber)}
              stateOfRound={stateOfRound}
            >
              <ButtonText>{b.color.toUpperCase() }</ButtonText>
            </ColorContainer>
          )}
        </ColorsRow>
      </PanelWrapper>
    </RememberColoursContainer>
  );
}

export default RememberColours;