import styled from 'styled-components';

export const StartButton = styled.div`
  width: 100px;
  padding: 5px 0;
  border: 2px solid;
  border-radius: 10px;
  text-align: center;
  background-color: white;
`;

export const GameBar = styled.div`
  margin: 20px 100px 0 0;
`;

export const QuickCalculationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const PanelWrapper = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  font-size: 30px;
  span {
    font-size: 30px;
  }
  input {
    height: 50px;
    font-size: 40px;
  }
`;

export const InfoText = styled.p`
  margin-top: 20px;
`;