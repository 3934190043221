import React from 'react';
import {
  SButtonWrapper,
  SButton
} from './styles';

const StandardButton = ({title, clickFunction = () => {}}) => {


  return (
    <SButtonWrapper>
      <SButton onClick={() => clickFunction()}>{title}</SButton>
    </SButtonWrapper>
  );
}

export default StandardButton;