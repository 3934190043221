import React, { useRef, useState } from 'react';
import NavbarSection from '../NavbarSection';
import {
  NavbarWrapper,
  NavbarTitle,
  NavbarGame,
  MainIcon,
  NavbarSections,
  NavbarSubSections,
  MenuIcon,
  MenuSections,
  SectionTitleMobile
} from './styles';
import icon from '../../assets/icon.png';
import menu from '../../assets/menu.svg';
import { useMediaDevices } from '../../utils';
import { useClickOutside } from '../../utils/hooks';

const Navbar = () => {
  const memory = [{remembercolour: 'Remember Colour'}, {optionsecuence: 'Option in the Sequence'}];
  const maths = [{quickcalculation: 'Quick Calculation'}];
  const speed = [{superreflex: 'Super Reflex'}];
  const { mediaIsPhone } = useMediaDevices();
  const [isVisible, setIsVisible] = useState(false);
  const MenuIconRef = useRef(null);
  useClickOutside(MenuIconRef, () => setIsVisible(false));

  return (
    <NavbarWrapper mediaIsPhone={mediaIsPhone}>
      <NavbarGame to="home">
        <MainIcon src={icon} alt='mainIcon'/>
        <NavbarTitle>EZgames</NavbarTitle>
      </NavbarGame>
      {!mediaIsPhone ? 
      <NavbarSections>
        <NavbarSubSections className="middleSections">
          <NavbarSection title="Memory" games={memory}/>
          <NavbarSection title="Maths" games={maths}/>
          <NavbarSection title="Speed" games={speed}/>
        </NavbarSubSections>
        <NavbarSubSections>
          <NavbarSection title="About"/>
        </NavbarSubSections>
      </NavbarSections> : 
      <MenuIcon ref={MenuIconRef}>
        <div onClick={() => setIsVisible(!isVisible)}>
          <img src={menu} alt='menu'/>
        </div>
        <MenuSections isVisible={isVisible ? 'visible' : 'hidden'}>
          <SectionTitleMobile to='/memory' onClick={() => setIsVisible(false)}>Memory</SectionTitleMobile>
          <SectionTitleMobile to='/maths' onClick={() => setIsVisible(false)}>Maths</SectionTitleMobile>
          <SectionTitleMobile to='/speed' onClick={() => setIsVisible(false)}>Speed</SectionTitleMobile>
        </MenuSections>
      </MenuIcon>}
      
    </NavbarWrapper>
  );
}

export default Navbar;
