import React, { useState, useRef } from 'react';
import { NavbarGame, NavbarSectionWrapper, SectionContainer, SectionTitle, ArrowIcon, ArrowIconWrapper } from './styles';
import { useClickOutside } from '../../utils/hooks';

const NavbarSection = ({ title, games }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ArrowIconRef = useRef(null);
  useClickOutside(ArrowIconRef, () => setIsVisible(false));
  
  return (
    // <NavbarSectionWrapper onMouseEnter={() => setIsVisible('visible')} onMouseLeave={() => setIsVisible('hidden')}>
    <NavbarSectionWrapper ref={ArrowIconRef}>
      <SectionTitle to={title.toLowerCase()} onClick={() => setIsVisible(false)}>{title}</SectionTitle>
      {games ?
      <>
        <ArrowIconWrapper onClick={() => setIsVisible(!isVisible)} >
          <ArrowIcon/>
        </ArrowIconWrapper>
        <SectionContainer isVisible={isVisible ? 'visible' : 'hidden'}>
          {games.map((game) =>
            <NavbarGame
              onClick={() => setIsVisible(false)}
              key={Object.keys(game)[0]}
              to={`${title.toLowerCase()}/${Object.keys(game)[0]}`}>
                {Object.values(game)[0]}
            </NavbarGame>
          )}
        </SectionContainer>
      </>
      : null}
    </NavbarSectionWrapper>
  );
}

export default NavbarSection;