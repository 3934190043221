import styled from 'styled-components';

export const GameBarWrapper = styled.div`
  margin: 20px 20px 0 0;
  width: ${({width}) => width};
`;

export const InfoText = styled.p`
  margin-top: 20px;
`;
