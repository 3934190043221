import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Analytics } from '@vercel/analytics/react';
import { Home, RememberColours, QuickCalculation, About, Memory, Maths, Speed, SuperReflex, OptionInTheSequence } from "./containers";
import { Navbar } from './components';
import { checkCookie, getCookie } from './utils';
import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Navigate to="home" />} />
          <Route path="home" element={<Home />} />
          {/* Memory */}
          <Route path="memory" element={<Memory />} />
          <Route path="memory/remembercolour" element={<RememberColours />} />
          <Route path="memory/optionsecuence" element={<OptionInTheSequence />} />
          {/* Maths */}
          <Route path="maths" element={<Maths />} />
          <Route path="maths/quickcalculation" element={<QuickCalculation />} />
          {/* Speed */}
          <Route path="speed" element={<Speed />} />
          <Route path="speed/superreflex" element={<SuperReflex />} />
          {/* About */}
          <Route path="about" element={<About />} />
        </Routes>
      </BrowserRouter>
      {!getCookie("user") ?
        <div className="cookies">
          <div>This page use cookies ONLY to save your records</div>
          <div className="exis" onClick={() => checkCookie()}>X</div>
        </div> : 
      null}
      <Analytics />
    </div>
  );
}

export default App;
