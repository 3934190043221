import styled from 'styled-components';

export const GameBar = styled.div`
  margin: 20px 100px 0 0;
  width: ${({width}) => width};
`;

export const RememberColoursContainer = styled.div`
  display: ${({display}) => display};
`;

export const PanelWrapper = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  width: ${({width}) => width};
`;

export const ColorsRow = styled.div`
  display: flex;
  justify-content: center;
`;

export const ColorContainer = styled.div`
  margin: 20px;
  min-width: 100px;
  max-width: 200px;
  width: 30%;
  min-height: 100px;
  max-height: 200px;
  background-color: ${({color}) => color};
  display: flex;
  justify-content: center;
  border: 2px solid;
  border-radius: 10px;

  
  :active {
    background-color: ${({stateOfRound}) => stateOfRound === 'PRES' ? 'white' : null};
  }
`;

export const ButtonText = styled.p`
  text-shadow: 1px 0 #fff, -1px 0 #fff, 0 1px #fff, 0 -1px #fff;
  font-weight: bold;
  align-self: center;
`;

export const InfoText = styled.p`
  margin-top: 20px;
`;