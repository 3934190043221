import React from "react";
import { Digits, MiliSec } from "./styles.js";

export default function Timer(props) {
  return (
    <div className="timer">
      <Digits>
        {("0" + Math.floor((props.time / 60000) % 60)).slice(-2)}:
      </Digits>
      <Digits>
        {("0" + Math.floor((props.time / 1000) % 60)).slice(-2)}.
      </Digits>
      <MiliSec>
        {Math.ceil(props.time / 100) % 10}
      </MiliSec>
    </div>
  );
}
