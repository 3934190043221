import React from 'react';
import { getCookie, useMediaDevices } from '../../utils';
import { GameBarWrapper, InfoText } from './styles';

const GameBar = ({title, description, stateOfRound, recordID, recordUnity }) => {

  const { mediaIsPhone } = useMediaDevices();

  return (
    <GameBarWrapper width={mediaIsPhone ? '100%' : '30%'}>
      <div>
        <h2>{title}</h2>
        <h4>{description}</h4>
      </div>
      {stateOfRound ? <InfoText>State of the round: {stateOfRound}</InfoText> : null}
      {getCookie(recordID) ? <InfoText>Record: {getCookie(recordID)} {recordUnity}</InfoText> : null}
    </GameBarWrapper>
  );
}

export default GameBar;