import React from 'react';
import image from '../../assets/simon.jpg';
import Card from '../../components/Card';
import {
  MemoryWrapper
} from './styles';

const Memory = () => {

  const memory = [{remembercolour: 'Remember Colour'}, {optionsecuence: 'Option in the Sequence'}];
  return (
    <MemoryWrapper>
      {memory.map((game) =>
        <Card
          key={Object.values(game)[0]}
          title={Object.values(game)[0]}
          category="Memory"
          image={image}
          path={`memory/${Object.keys(game)[0]}`}
        />
      )}
    </MemoryWrapper>
  );
}

export default Memory;