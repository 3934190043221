import React from 'react';
import Maths from '../Maths';
import Memory from '../Memory';
import Speed from '../Speed';

const Home = () => {
  return (
    <main style={{ padding: "1rem 0" }}>
      <h3>Maths</h3>
      <Maths />
      <h3>Memory</h3>
      <Memory />
      <h3>Speed</h3>
      <Speed />
    </main>
  );
}


export default Home;