import styled from 'styled-components';
import { Link } from "react-router-dom";

export const NavbarGame = styled(Link)`
  padding: 5px 10px;
  text-decoration: none;
  color: black;
  cursor: pointer;
  border-radius: 5px;
  :hover{
    background-color: lightgreen;
  }
`;

export const NavbarSectionWrapper = styled.div`
  padding: 10px 20px;
  border-right: 2px solid green;
  align-self: center;
`;

export const SectionContainer = styled.div`
  visibility: ${(props) => props.isVisible};
  display: flex;
  flex-direction: column;
  padding: 5px;
  border: 1px solid green;
  border-radius: 5px;
  position: absolute;
  background-color: white;
`;

export const SectionTitle = styled(Link)`
  text-decoration: none;
  font-weight: 600;
  padding: 10px 5px;
  border-radius: 5px;
  color: black;
  transition: 0.5s;
  :hover{
    background-color: #dfffd0;
  }
`;

export const ArrowIcon = styled.i`
  border: solid black;
  margin-bottom: 3px;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
`;

export const ArrowIconWrapper = styled.div`
  display: flex;
  display: inline-block;
  padding: 10px 5px;
  border-radius: 5px;
  transition: 0.5s;
  cursor: pointer;
  :hover{
    background-color: #dfffd0;
  }
`;
