import styled from 'styled-components';

export const SRelfexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const ReflexButton = styled.div`
  margin: 5px;
  padding: 20px;
  cursor: pointer;
  border: 2px solid black;
  border-radius: 5px;
  text-align: center;
`;

export const InfoText = styled.p`
  margin-top: 20px;
`;

export const TimeInput = styled.input`
    text-align: center;
    background-color: lightgreen;
    border: 0;
    cursor: unset;
    font-size: 30px;
`;

export const PanelWrapper = styled.div`
  margin: 30px 0;
`;

export const StartButton = styled.div`
  width: 100px;
  padding: 5px 0;
  border: 2px solid;
  border-radius: 10px;
  text-align: center;
  background-color: white;
`;

export const GameBar = styled.div`
  margin: 20px 100px 0 0;
`;
