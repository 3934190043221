import styled from 'styled-components';

export const CardWrapper = styled.div`
  min-width: 200px;
  margin: 10px;
  height: 300px;
  border: 2px solid green;
  border-radius: 5px;
  /* box-shadow: 5px 10px 18px #888888; */
  box-shadow: 12px 12px 10px -12px rgba(66, 68, 90, 1);
  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:hover {
    transform: translate(0, -3px);
  }
  text-align: center;
`;


export const CardImageDiv = styled.div`
  width: 100%;
  height: 60%;
`;

export const CardImage = styled.img`
  border-radius: 5px;
  width: 100%;
  height: 100%;
`;

export const CardTextDiv = styled.div`
  text-decoration: none;
  margin-top: 20px;
`;