import styled from 'styled-components';
import { Link } from "react-router-dom";

export const NavbarWrapper = styled.div`
  display: flex;
  border: 2px solid #005000;
  border-radius: 10px;
  margin: 10px 0 0;
  padding: 15px;
  justify-content: ${({mediaIsPhone}) => mediaIsPhone ? 'space-between' : 'initial'};
`;

export const NavbarGame = styled(Link)`
  margin: 0 10px;
  text-decoration: none;
  color: black;
  cursor: pointer;
  display: flex;
`;

export const NavbarTitle = styled.div`
  -webkit-text-fill-color: white;
  -webkit-text-stroke-color: #005000;
  -webkit-text-stroke-width: 1.00px; 
  margin: 0 30px 0 20px;
  text-shadow: 2px 2px #005000;
  font-size: 24px;
  font-weight: 900;
  align-self: center;
`;

export const MainIcon = styled.img`
  max-height: 40px;
  padding: 5px;
  align-self: center;
  border: 2px solid #005000;
  border-radius: 50%;
  background-color: white;
`;


export const NavbarSections = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const NavbarSubSections = styled.div`
  display: flex;

  &.middleSections {
    margin: auto;
  }
  
  & > :last-child {
    border-right: 0px;
  }
`;


export const MenuIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const MenuSections = styled.div`
  visibility: ${(props) => props.isVisible};
  display: flex;
  flex-direction: column;
  padding: 5px;
  border: 1px solid green;
  border-radius: 5px;
  background-color: white;
  position: fixed;
  left: 50%;
  top: 20%;
  transform: translate(-50%, -50%);
  width: 90%;
  & * {
    margin: 5px;
    text-align: center;
  }
`;

export const SectionTitleMobile = styled(Link)`
  text-decoration: none;
  font-weight: 600;
  padding: 5px 5px;
  border-radius: 5px;
  color: black;
  :hover{
    background-color: #dfffd0;
  }
`;