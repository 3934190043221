import React from 'react';
import Card from '../../components/Card';
import image from '../../assets/calculation.png';
import {
  MathsWrapper
} from './styles';

const Maths = () => {

  const maths = [{quickcalculation: 'Quick Calculation'}];
  return (
    <MathsWrapper>
      {maths.map((game) =>
        <Card
          key={Object.values(game)[0]}
          title={Object.values(game)[0]}
          category="Maths"
          image={image}
          path={`maths/${Object.keys(game)[0]}`}
        />
      )}
    </MathsWrapper>
  );
}

export default Maths;