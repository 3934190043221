import React, { useState, useRef } from 'react';
import { StandardButton } from '../../../components';
import { setCookie, getCookie, sleep } from '../../../utils';

import {
  OptionInTheSequenceWrapper,
  GameBar,
  InfoText,
  PanelWrapper
} from './styles';

const OptionInTheSequence = () => {

  const [stateOfRound, setStateOfRound] = useState("STOP");
  const [round, setRound] = useState(0);
  const [show, setShow] = useState(-1);
  const [question, setQ] = useState(-1);
  const [indexQ, setindexQ] = useState(-1);
  const ref = useRef(null);

  const lista = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];

  async function runRound() {
    // const i = Math.random() * 5;
    setShow(null);
    let l = [];
    await sleep(1000);
    for (let i = 1; i <= round + 3; i++) {
      const x = lista[Math.floor(Math.random() * 9)];
      setShow(x);
      l.push(x);
      await sleep(1000);
    }
    const y = Math.floor(Math.random() * (round + 3));
    setindexQ(y);
    setQ(l[y]);
    setStateOfRound("PRES");
  }

  const startTheGame = () => {
    setStateOfRound("SHOW");
    setRound(1);
    runRound();
  };

  const end = () => {
    setRound(0);
    setStateOfRound("STOP");
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (ref.current.value === question.toString()) {
        setRound(round + 1);
        runRound();
        setStateOfRound("SHOW");
        ref.current.value = "";
      } else end();
    }
  };

  return (
    <OptionInTheSequenceWrapper>
        <GameBar>
          <div>
            <h2>Remember the secuence</h2>
            <h4>Answer the index of the option</h4>
          </div>
          <StandardButton title="Start" clickFunction={() => startTheGame()} />
          <InfoText>State of the round: {stateOfRound}</InfoText>
          <InfoText>Round: {round}</InfoText>
          <InfoText>Record: {getCookie('record') ? getCookie('record') : 0}</InfoText>
        </GameBar>
        <PanelWrapper>
          {stateOfRound === "PRES" ? (
            <div>numero en la posicion {indexQ + 1} es: {question}</div>
          ) : (
            <div>{show}</div>
          )}
          <input type="text" onKeyDown={handleKeyDown} ref={ref}></input>
        </PanelWrapper>
        
    </OptionInTheSequenceWrapper>
  );
}

export default OptionInTheSequence;