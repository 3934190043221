import React from 'react';
import {
  NumberKeyboardWrapper,
  NumberRow,
  Number
} from './styles';

const NumberKeyboard = ({stateOfRound, inputRef, clickEnter }) => {

  const writingNumber = (n) => {
    // if (stateOfRound !== "PRES") return;
    inputRef.current.value = `${inputRef.current.value}${n}`;
  };

  return (
    <NumberKeyboardWrapper>
      <NumberRow>
        {[1, 2, 3].map((n) => <Number key={n} onClick={() => writingNumber(n)}> {n} </Number>)}
      </NumberRow>
      <NumberRow>
        {[4, 5, 6].map((n) => <Number key={n} onClick={() => writingNumber(n)}> {n} </Number>)}
      </NumberRow>
      <NumberRow>
        {[7, 8, 9].map((n) => <Number key={n} onClick={() => writingNumber(n)}> {n} </Number>)}
      </NumberRow>
      <NumberRow>
        <Number onClick={() => writingNumber(0)}>0</Number>
        <Number onClick={() => clickEnter()}> Enter </Number>
        <Number onClick={() => (inputRef.current.value = null)}> Delete All </Number>
      </NumberRow>
    </NumberKeyboardWrapper>
  );
}

export default NumberKeyboard;