import styled from 'styled-components';

export const OptionInTheSequenceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const GameBar = styled.div`
  margin: 20px 100px 0 0;
`; 

export const InfoText = styled.p`
  margin-top: 20px;
`;

export const PanelWrapper = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: column;
`;