import React, { useEffect, useState, useRef } from 'react';
import { setCookie, getCookie } from '../../../utils';
import { NumberKeyboard, Cronometer, GameBar } from '../../../components';
import {
  QuickCalculationContainer,
  PanelWrapper
} from './styles';

const QuickCalculation = () => {
  const inputRef = useRef(null);
  const [stateOfRound, setStateOfRound] = useState("STOP");
  const [numberOperations, setNumberOperations] = useState(0);
  const [operation, setOperation] = useState([]);
  const [, setRecord] = useState(0); //This is done to refresh the cookies

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return;
  },[stateOfRound]);

  const startTheGame = () => {
    generarOperacion(10);
    setNumberOperations(0);
    setStateOfRound("PRES");
  }

  const finishTheGame = () => {
    setStateOfRound("STOP");
    inputRef.current.value = null;
    setOperation([]);
    if(getCookie('recordQuickCalculation') < numberOperations) {
      setCookie('recordQuickCalculation', numberOperations);
      setRecord(numberOperations);
    }
  }

  const generarOperacion = (max) => {
    const a = Math.floor(Math.random() * max);
    const b = Math.floor(Math.random() * max);
    setOperation([a, b, a + b]);
  };

  const nextOperation = () => {
    generarOperacion(10);
    setNumberOperations(numberOperations + 1);
    inputRef.current.value = null;
  };

  return (
    <QuickCalculationContainer>
      <GameBar
        title="Quick calculation"
        description="Make the most calculation in 1 minute."
        stateOfRound={stateOfRound}
        recordID="recordQuickCalculation"
        recordUnity="operations"
      />
      <Cronometer stateOfRound={stateOfRound} maxTime={60000} startGame={() => startTheGame()} stopGame={() => finishTheGame()}/>
      <PanelWrapper>
        <span>{operation.length > 0 ? `${operation[0]} + ${operation[1]}` : "READY?"}</span>
        <input ref={inputRef} type="text" id="name" name="name" required maxLength={2} size={10}/>
        <NumberKeyboard
          stateOfRound={stateOfRound}
          inputRef={inputRef}
          clickEnter={() => inputRef.current.value === operation[2].toString() ? nextOperation() : {}}/>
      </PanelWrapper>
    </QuickCalculationContainer>
  );
};

export default QuickCalculation;